<template>
  <div class="article">
    <div class="options" :class="{ active: targetUser }">
      <div class="input_search">
        <input type="text" placeholder="사용자 조회" disabled />
        <button @click="openModal()">
          <i class="fa fa-search"></i>
        </button>
      </div>
      <div class="input_text">
        <label>이름</label>
        <input
          type="text"
          placeholder="이름"
          :value="targetUser !== null ? targetUser.name : ''"
          readonly
        />
      </div>
      <div class="input_text">
        <label>아이디</label>
        <input
          type="text"
          placeholder="아이디"
          :value="targetUser !== null ? targetUser.account : ''"
          readonly
        />
      </div>
      <div class="input_text">
        <label>권한</label>
        <input
          type="text"
          placeholder="권한"
          :value="
            targetUser !== null
              ? findInfoFromId(user_role_type, targetUser.user_role_type_id)
                  .name
              : ''
          "
          readonly
        />
      </div>
      <button class="btn_sub2" @click="submitForm()" :disabled="isValidModify">
        저장
      </button>
    </div>
    <div class="authority" v-if="targetUser">
      <div v-for="item in filterCheck" :key="item.id">
        <div class="auth_head">
          <h5>{{ item.name }}</h5>
          <div>
            <span>열람</span>
            <span>수정</span>
          </div>
        </div>
        <div
          class="auth_cont"
          v-for="subItem in item.sub_menu"
          :key="subItem.id"
        >
          <div>
            <span>{{ subItem.name }}</span>
            <div>
              <div
                class="input_checkbox"
                @click.prevent="setCheckAuth(subItem, 1)"
              >
                <label
                  for="checkbox1"
                  class="chk_box"
                  :class="{
                    active: subItem.permission == 1 || subItem.permission == 2,
                  }"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input type="checkbox" id="checkbox1" />
              </div>
              <div
                class="input_checkbox"
                @click.prevent="setCheckAuth(subItem, 2)"
              >
                <label
                  for="checkbox2"
                  class="chk_box"
                  :class="{ active: subItem.permission == 2 }"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input type="checkbox" id="checkbox2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <user-modal
      v-if="isUserModalOpen"
      :users="
        users.filter(
          x => x.user_role_type_id !== 1 && x.user_role_type_id !== 2,
        )
      "
      @onclose="isUserModalOpen = false"
      @onselect="
        $event => {
          targetedUser($event);
          isUserModalOpen = false;
        }
      "
    ></user-modal>
    <div class="modal_layer" v-if="isUserModalOpen" @click="closeModal"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import fetchMixin from '@/mixins/fetch';
import UserModal from '@/layouts/components/UserModal';

export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin],
  components: {
    UserModal,
  },
  data() {
    return {
      isUserModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      targetUser: 'getTargetUserFromAuthority',
      filterCheck: 'getFilterCheckFromAuthority',
      editCheck: 'getEditCheckFromAuthority',
      menuItems: 'getMenuItems',
      users: 'getUsersTempFromUserPage',
      user_role_type: 'getUserRoleTypeFromUserPage',
    }),
    isValidModify() {
      if (this.targetUser != null) {
        const modifyData = JSON.stringify(this.filterCheck);
        const originData = this.editCheck;

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return true;
    },
  },
  methods: {
    openModal() {
      this.isUserModalOpen = true;
    },
    closeModal() {
      this.isUserModalOpen = false;
    },
    targetedUser(arg) {
      this.$store.commit('setTargetUserToAuthority', arg);
      this.FETCH_WITH_PAYLOAD('FETCH_USER_AUTH', arg.id, '사용자 메뉴 권한');
    },
    setCheckAuth(menu, perId) {
      console.log(menu, perId);
      if (menu !== undefined) {
        if (perId == 1) {
          if (menu.permission == 1 || menu.permission == 2) {
            menu.permission = 0;
          } else {
            menu.permission = 1;
          }
        } else if (perId == 2) {
          if (menu.permission == 2) {
            menu.permission = 1;
          } else {
            menu.permission = 2;
          }
        }
      }
    },
    async fetch_user_list() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    async submitForm() {
      let data = this.lodash.clonedeep(this.filterCheck);
      let filterData = data.map(x => x.sub_menu).reduce((a, b) => a.concat(b));
      let data2 = this.lodash.clonedeep(this.menuItems);
      let menuData = data2.map(x => x.sub_menu).reduce((a, b) => a.concat(b));
      console.log('값 확인', filterData);

      filterData.push(menuData.find(x => x.sub_detail == '/system/about'));
      const filterLast = filterData[filterData.length - 1];
      filterLast.permission = 1;
      this.showSpinner();
      await this.$store
        .dispatch('INSERT_USER_AUTH', {
          user_id: this.targetUser.id,
          data_list: filterData,
        })
        .then(() => {
          this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
          this.FETCH_WITH_PAYLOAD(
            'FETCH_USER_AUTH',
            this.targetUser.id,
            '사용자 메뉴 권한',
          );
        })
        .catch(error => {
          this.openOneButtonModal('저장 중 오류', '권한 저장 중 오류');
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.menuItems.length == 0) {
      await this.showSpinner();
      this.$store
        .dispatch('FETCH_ALL_MENUS', this.userId)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '메뉴 로드 중 오류',
            '사용자 메뉴 리스트를 불러오는 중 오류 발생. 다시 로그인하여주십시오.',
          );
        });
      this.hideSpinner();
    }
    if (this.users.length < 1) {
      await this.fetch_user_list();
    }
    await this.FETCH('FETCH_USER_ROLE_TYPE', '사용자 권한');

    if (this.$route.meta.select != undefined) {
      await this.$store.commit(
        'setTargetUserToAuthority',
        this.$route.meta.select,
      );
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_USER_AUTH',
        this.$route.meta.select.id,
        '사용자 메뉴 권한',
      );
      delete this.$route.meta.select;
    }
  },
};
</script>

<style lang="scss" scoped></style>
