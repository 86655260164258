<template>
  <div class="modalPopup user_modal" v-if="modalClose">
    <div class="modal_header">
      <h3 class="title">
        사용자 목록
      </h3>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>이름</th>
              <th>아이디</th>
              <th>권한</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users"
              :key="user.id"
              @click="selectedIndex = index"
              @dblclick="submit"
              :class="{ active: selectedIndex == index }"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.account }}</td>
              <td>
                {{
                  findInfoFromId(user_role_type, user.user_role_type_id).name
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal_footer">
      <div class="warning" v-show="error_text != ''">
        {{ error_text }}
      </div>
      <button type="button" class="btn_sub2" @click="submit">
        선택
      </button>
    </div>
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { mapGetters } from 'vuex';

export default {
  mixins: [fetchMixin, DRAG_MODAL_MIXIN],
  props: ['users'],
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      modalClose: true,
    };
  },
  computed: {
    ...mapGetters({
      user_role_type: 'getUserRoleTypeFromUserPage',
    }),
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    submit() {
      if (this.selectedIndex == -1) {
        this.error_text = '사용자를 선택하세요';
      } else {
        console.log(this.users[this.selectedIndex]);
        this.$emit('onselect', this.users[this.selectedIndex]);
      }
    },
  },
  created() {
    this.FETCH('FETCH_USER_ROLE_TYPE', '사용자 권한');
  },
};
</script>

<style lang="scss" scoped></style>
